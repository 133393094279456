@import "../../assets/styles/scss/variables";

#page-header.highlight {
  background-color: transparent;
  color: $color-text;

  .grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    @media (max-width:1199px) {
      display: flex;
      flex-direction: column;
    }
  }

  .poster {
    border-radius: $rounded;
    overflow: hidden;
    display: flex;
  }

  .content {
    background-color: $color-bloc;
    color: $color-text;
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    padding-bottom: 4.5rem;
    border-radius: $rounded;
    position: relative;

    .btn-icon {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .categories {
    text-transform: uppercase;
    color: $color-text;
    margin-top: .4rem;
  }

  .date {
    padding: .7rem 1.4rem;
    color: $color-primary;
    font-weight: 600;
    background-color: $color-primary-light;
    border-radius: 4rem;
    display: flex;
    width: fit-content;
    line-height: 1;
    transition: all .2s linear;
    margin-bottom: .6rem;

    @media (max-width:991px) {
      font-size: .7rem;
    }
  }
}

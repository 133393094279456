@import "../../assets/styles/scss/variables";

#rates {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.5rem;

  @media (max-width:991px) {
    display: flex;
    flex-direction: column;
  }

  .rate {
    padding: 1rem 0;
    border-bottom: 1px solid $color-gray-lighter;
    font-size: .8rem;
    line-height: normal;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    &:nth-last-child(-n+2) {
      border-bottom: none;
    }

    .title {
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: inherit;
      line-height: inherit;
    }

    .price {
      white-space: nowrap;
    }
  }
}

@import "../../assets/styles/scss/variables";

.date {
  padding: .7rem 1.4rem;
  color: $color-primary;
  font-weight: 600;
  background-color: $color-primary-light;
  border-radius: 4rem;
  display: flex;
  width: fit-content;
  line-height: 1;
  transition: all .2s linear;

  @media (max-width:991px) {
    font-size: .7rem;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-primary-light;
  }

  &.color-white {
    color: white;
  }

  &.color-black {
    color: black;
  }
}
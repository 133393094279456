//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: transparent;
	margin-top: 10rem;
	color: $color-title;

	@media (max-width:767px) {
		margin-top: 6rem;
	}

	#footer-top {
		grid-template-columns: 1fr auto auto;

		address {
			padding: 1.4rem 1.5rem;
		}

		a {
			font-weight: 800;
			padding: .9rem 1.3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			color: inherit;

			&:hover {
				background-color: $color-primary-dark;
				color: white;
			}
		}

		i {
			font-size: 1.9rem;
		}

		ul.socials {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all .2s linear;
				padding: 0;

				&:hover {
					background-color: $color-primary-dark;

					a {
						background-color: transparent;
					}
				}
			}
		}

		@media (max-width:1260px) {
			grid-template-columns: 2fr;

			address {
				grid-column: span 2;
			}
		}

		@media (max-width:991px) {

			address {
				span.bold {
					display: block;
				}
			}
		}

		@media (max-width:576px) {
			display: flex;
			flex-direction: column;
		}
	}

	#footer-bottom {
		margin-top: 3rem;
		opacity: .5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 1rem;

		>div:first-child {
			display: flex;
			align-items: center;
			gap: 2.5rem;
		}

		a, p {
			color: inherit;
			font-size: inherit;
			line-height: normal;
			font-weight: inherit;
			margin: 0;
		}

		@media (max-width:991px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: .5rem;

			>div:first-child {
				width: 100%;
				justify-content: space-between;
			}
		}

		@media (max-width:576px) {

			>div:first-child {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: .5rem;
			}
		}
	}
}

body.colored-bg {

	#footer {
		#footer-bottom {
			opacity: 1;
		}
	}
}

.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}

//
// Template Styles
// --------------------------------------------------

#main {
    padding-top: calc(96px + 3rem); // Header height
    margin-top: 6rem;
    z-index: 15;

    @media (max-width:1260px) {
        padding-top: 0;
        margin-top: 8rem;
    }
}

body.error404 {
    min-height: 100vh;
}

.bloc-wrapper {
    &.left-align {
        margin-left: clamp(-2rem, -3.65vw, -3rem);

        @media (max-width:991px) {
            margin-left: 0;
        }
    }
}

#single-show {

    .show-content.grid {
        grid-template-columns: 3fr 1fr;
        align-items: flex-start;
        margin-top: 2rem;

        @media (max-width:1441px) {
            grid-template-columns: 2fr 1fr;
        }

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:991px) {
            display: flex;
        }
    }

    .show-content.grid,
    .left.grid {
        gap: 2rem;
    }

    .left.grid {
        @media (max-width:991px) {
            width: 100%;
        }
    }

    .gallery-slider {
        width: 100%;
        border-radius: $rounded;

        .swiper-slide {
            height: auto;
            position: relative;
            padding-top: 66.6666666667%;
            background-color: $color-bloc;

            img, video {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .swiper-buttons {
            top: initial;
            bottom: 1.1rem;
            padding: 0 1.1rem;
            transform: none;
            justify-content: flex-end;
            gap: 1rem;
        }
    }

    .credits-bloc {

        ul.credits {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 1rem 0;
                border-bottom: 1px solid $color-gray-lighter;

                &:last-child {
                    border-bottom: none;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    #prog {
        margin-top: 6rem;

        @media (max-width:991px) {
            margin-top: 4rem;
        }
    }
}

#page-shows {

    .years-nav {
        margin-top: 6.7rem;
        margin-bottom: 2.2rem;

        @media (max-width:991px) {
            margin-top: 4rem;
        }

        .ts-wrapper {
            margin-left: 2.1rem;

            @media (max-width:991px) {
                margin-left: 0;
            }
        }
    }

    #archives {

        .show-bloc {
            display: none;

            &.is-active {
                display: grid;

                @media (max-width:1080px) {
                    display: flex;
                }
            }
        }
    }
}

#page-association {

    .grid {
        grid-template-columns: 8fr 4fr;

        @media (max-width:1441px) {
            grid-template-columns: 7fr 5fr;
        }

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column;
        }
    }

    .bloc-wrapper.rates {
        grid-column-start: 2;
        grid-row-start: 1;

        @media (max-width:1199px) {
            margin-left: clamp(-2rem,-3.65vw,-3rem);
        }

        @media (max-width:991px) {
            margin-left: 0;
        }

        #rates {
            display: flex;
            flex-direction: column;
        }

        .descr {
            margin-top: 1.5rem;
        }
    }
}

#page-header {
    margin-bottom: 6rem;

    @media (max-width:991px) {
        margin-bottom: 4rem;
    }
}

.shows {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

#page-homepage {

    .blocs {
        margin-bottom: 6rem;

        @media (max-width:991px) {
            margin-bottom: 4rem;
        }
    }
}

#page-career {

    .grid {
        grid-template-columns: 4fr 8fr;

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column;
        }
    }
}

#page-collaborators {

    .collaborator-list {
        margin-bottom: 6rem;
        display: flex;
        flex-direction: column;

        .collaborator-list-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 8.89rem;

            .collaborator-item {
                display: flex;
                flex-direction: column;
                column-gap: .33rem;
                padding: 1.16rem 0;
                border-top: solid 1px $color-gray-lighter;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-top: 0;
                }

                .comity-title {
                    font-weight: 600;
                }

                .comity-function {
                    color: #303030;
                }

                p {
                    font-size: 0.88889rem;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .collaborator-grid {
        display: flex;
        flex-direction: column;
        gap: 2.22rem;

        .collaborator-grid-content {
            display: grid;
            row-gap: 2.22rem;
            grid-template-columns: repeat(5, 1fr);
            column-gap: $gap;
        }
    }

    @media (max-width:1499px) {

        .collaborator-list {
            .collaborator-list-content {
                grid-template-columns: repeat(3, 1fr);
                column-gap: 5.89rem;

                .collaborator-item {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        border-top: 0;
                    }
                }
            }
        }

        .collaborator-grid {
            .collaborator-grid-content {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    @media (max-width:1399px) {
        .collaborator-grid {
            .collaborator-grid-content {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    @media (max-width:991px) {
        .collaborator-list {
            .collaborator-list-content {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 4.89rem;

                .collaborator-item {

                    &:nth-child(1),
                    &:nth-child(2) {
                        border-top: 0;
                    }
                }
            }
        }

        .collaborator-grid {
            .collaborator-grid-content {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (max-width:767px) {
        .collaborator-list {
            .collaborator-list-content {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 2.89rem;

                .collaborator-item {

                    &:nth-child(1),
                    &:nth-child(2) {
                        border-top: 0;
                    }
                }
            }
        }

        .collaborator-grid {
            .collaborator-grid-content {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (max-width:440px) {
        .collaborator-grid {
            .collaborator-grid-content {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
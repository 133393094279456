@import "../../assets/styles/scss/variables";

#gallery {
  margin-top: 6rem;

  @media (max-width:991px) {
      margin-top: 4rem;
  }

  .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width:1199px) {
          grid-template-columns: repeat(2, 1fr);
      }


      img {
          overflow: hidden;
          border-radius: $rounded;
      }
  }
}

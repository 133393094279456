@import "../../assets/styles/scss/variables";

#cta-newsletter {
	margin-top: 6rem;
	border-radius: $rounded;
	border: 2px dashed $color-primary;
	padding: 3rem 8.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2.big {
		margin-bottom: 1.5rem;
		color: $color-title;
		text-align: center;
	}

	.form-wrapper {
		width: 100%;
		max-width: 66%;

		form {
			display: flex;
			align-items: stretch;
			gap: $gap;
		}

		.gform_footer {
			margin-top: 0;
		}
	}

	@media (max-width:1260px) {
		padding: 3rem 4rem;

		.form-wrapper {
			max-width: 83%;
		}
	}

	@media (max-width:991px) {

		.form-wrapper {

			form {
				flex-direction: column;

				.gform_footer {

					button {
						width: 100%;
					}
				}
			}
		}
	}

	@media (max-width:767px) {
		margin-top: 3rem;

		.form-wrapper {
			max-width: 100%;
		}
	}
}

body.colored-bg {

	#cta-newsletter {
		border-color: $color-bloc;
	}
}
@import "../../assets/styles/scss/variables";

#partners-section {
  margin-top: 6rem;

  @media (max-width:991px) {
    margin-top: 4rem;
  }

  .partners {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 8.3rem;
    row-gap: 3rem;

    @media (max-width:1441px) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 6rem;
    }

    @media (max-width:991px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
    }

    @media (max-width:767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width:576px) {

    .bloc {
      padding: 2rem;
    }
  }
}
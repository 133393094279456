//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: transparent;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 21;
	transition: all 0.4s linear;
	margin-top: 3rem;

	@media (max-width:1260px) {
		margin-top: 2.2rem !important;
		position: fixed;

		&.is-open {

			#header-content {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		>.container {
			width: calc(100% - 2rem);
		}
	}

	&.sticky {
		margin-top: 2rem;

		#header-content {
			padding: 1.5rem 2rem;
			padding-left: 0;
		}

		#logo {
			height: 100%;
			padding: 0;
			left: 1rem;
		}

		#menu {

			.mega-menu-container {
				top: calc(100% + 1.2rem + 1.5rem);
			}
		}
	}

	#header-content {
		border-radius: $rounded;
		background-color: $color-bloc;
		padding: 1.8rem 2rem;
		padding-left: 0;
		display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
		transition: all .4s linear;

		@media (max-width:1260px) {
			padding: .5rem !important;
			padding-left: 0 !important;
		}
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;
		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: white;
			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:991px) {
			display: block;
		}
	}

	#logo {
		width: auto;
		height: 230%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
		padding-top: $xsmall;
		transition: all .6s linear;

		a {
			display: flex;
			width: auto;
			height: 100%;

			svg {
				width: auto;
				height: 100%;

				path {
					fill: $color-logo;
				}
			}
		}

		@media (max-width:1441px) {
			height: 170%;
		}

		@media (max-width:1260px) {
			height: 230%;
		}
	}

	#menu {
		transition: all 0.4s linear;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 4.5rem;

		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 5rem;

				@media (max-width:1600px) {
					gap: 2.5rem;
				}

				@media (max-width:1441px) {
					gap: 1.5rem;
				}
				li.first-level {
					position: relative;
					a {
						font-size:1rem;
						line-height: normal;
						display: block;
						color: $color-text;
						font-weight: 800;
						position: relative;
						text-transform: uppercase;

						&:hover {
							color: $color-primary;
						}
					}

					&.is-active {

						>a {
							color: $color-primary;
						}

						&.has-children {
							
							>a {
								&::after {
									transform: rotate(180deg);
								}
							}
						}
					}
					&.current_page_item,
					&.current_page_ancestor,
					&.current_page_parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: $color-primary;
						}
						.sub-menu {
							li {
								a {
									color: $color-text;
								}
							}
						}
					}
					&.has-children {

						>a {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: .6rem;

							&::after {
								content: "\f078";
								font-family: $fontawesome;
								font-weight: bold;
								color: inherit;
							}
						}
					}
				}
			}
		}

		#menu-main-container {
			@media (max-width:1260px) {
				display: none !important;
				visibility: hidden;
			}
		}

		#burgermenu-trigger {
			display: none;
			padding: 0;
			background-color: transparent;
			color: $color-primary;
			font-size: 2rem;

			a {
				color: inherit;
				display: flex;
				&:hover {
					color: $color-primary-dark;
				}
			}

			@media (max-width:1260px) {
				display: flex;
			}

			i {
				&.fa-xmark {
					display: none;
				}
			}

			&.is-active {

				i {
					&.fa-xmark {
						display: initial;
					}
					&.fa-bars {
						display: none;
					}
				}
			}
		}

		.mega-menu-container {
			position: absolute;
			left: 0;
			top: calc(100% + 1.2rem + 1.8rem);
			width: fit-content;
			background-color: $color-bloc;
			border-radius: $rounded;
			overflow: hidden;
			display: none;

			&.is-open {
				display: block;
			}

			.second-level-container {
				flex-direction: column;
				align-items: flex-start !important;
				justify-content: flex-start !important;
				gap: 0 !important;
				text-align: left;
				padding: 0;
				margin: 0;
				list-style: none;

				li {
					padding: 0 1rem;
					width: 100%;
					
					&:last-child {
						a {
							border-bottom: none;
						}
					}
					a {
						border-bottom: 1px solid $color-primary-light;
						background-color: $color-bloc;
						padding: 1rem;
						display: flex !important;
						text-align: left;
						align-items: flex-start;
						white-space: nowrap;

						&:hover {
							color: $color-primary-light;
						}
					}
				}
			}
		}

		#mobile-menu {
			display: none !important;
			visibility: hidden;
			position: fixed;
			width: 100%;
			height: calc(100vh - 104px);
			left: 0;
			top: 104px;
			z-index: -1;

			.container,
			#mobile-menu-container {
				height: 100%;
			}
			
			.mobile-menu-container {
				background-color: $color-bloc;
				width: calc(100% - 2rem);
				height: calc(100% - 1.2rem);
				overflow: hidden;
				border-radius: $rounded;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				margin-inline: auto;

				#mobile-menu-container {
					position: relative;
					padding: 1.5rem;
					padding-top: 2rem;
					padding-right: .5rem;
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				height: 100%;
				overflow-y: auto;

				li {
					width: 100%;

					&.mobile-first-level {
						padding: .8rem 0;

						&.has-children {
							position: relative;

							&.is-active {

								>a {
									color: $color-primary;

									&::after {
										transform: rotate(180deg);
									}
								}
							}

							>a {
								align-items: center;
								justify-content: space-between;
								position: relative;
								&::after {
									content: "\f078";
									font-family: $fontawesome;
									font-weight: 400;
									color: $color-primary;
								}
							}
						}
					}

					&.mobile-second-level {
						position: relative;
						a {
							padding: .5rem .3rem;
						}
					}

					a {
						font-weight: 800;
						display: flex;
						color: $color-text;
						text-transform: uppercase;
						line-height: normal;

						&:hover {
							color: $color-primary-dark;
						}
					}

					ul.mobile-second-level-container {
						display: none;

						&.is-open {
							display: block;
							padding: .5rem 0;
						}

						li {

							a {
								font-size: $xsmall;
								line-height: normal;
							}
						}
					}
				}
			}

			@media (max-width:1260px) {
				visibility: visible;

				&.is-open {
					display: block !important;
				}
			}

			@media (max-width:767px) {
				top: 94px;
				height: calc(100vh - 94px);
			}
		}

		@media (max-width:1260px) {
			position: relative;
		}
	}
}

body.mega-menu-open {
	position: relative;

	.mega-menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vw;
		background-color: #00000063;
		z-index: 20;
	}
}
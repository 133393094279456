@import "../../assets/styles/scss/variables";

#page-header.standard-header,
#page-header-content.standard-header {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  clip-path: var(--clip-path-value);

  .header-grid {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
  }

  .top {
    width: 100%;
    display: grid;
    grid-template-columns: 8fr 4fr;

    .title-container-bg {
      background-image: var(--header-image);
      background-size: calc(var(--content-width) + 2.8rem);
      background-position: right top;
      height: auto;
      width: 100%;
      border-radius: $rounded $rounded 0 0;

      &:before {
        content: "";
        height: 2rem;
        width: 2rem;
        position: relative;
        background-image: var(--header-image);
        background-size: calc(var(--content-width) + 2.8rem);
        display: block;
        left: -2rem;
        bottom: calc(-100% + 2rem);
        mask-image: url(../../assets/images/mask.svg);
        background-position-x: calc(1*(var(--right-width)) + 2rem);
        background-position-y: calc(-1*(var(--top-height)) - -2rem);
      }
    }

    .title-container {
      display: flex;
      position: relative;
      background-color: transparent;
      overflow: visible;
      padding: 0 $gap $gap 0;

      .header-title {
        width: 100%;
        padding: 2.22rem;
        font-size: clamp(1.8rem, 4vw, 2.9rem);
        line-height: normal;
        font-weight: 800;
      }
    }
  }

  .bottom {
    display: grid;
    grid-template-columns: 4fr 8fr;
    width: 100%;

    &.flex-container {
      display: flex;
      width: 100%;

      .content-container-bg {
        background-image: var(--header-image);
        background-size: calc(var(--content-width) + 2.8rem);
        min-height: 22rem;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        border-radius: $rounded 0 $rounded $rounded;
        overflow: hidden;
      }
    }

    .content-container-bg {
      background-image: var(--header-image);
      background-size: calc(var(--content-width) + 2.8rem);
      min-height: 30rem;
      height: 100%;
      width: 100%;
      border-radius: $rounded 0 $rounded $rounded;
      overflow: hidden;
    }

    .content-container {
      display: flex;
      background-color: transparent;
      position: relative;
      height: 100%;
      padding: 0 $gap 0 0;

      .header-content {
        padding: 2.22rem;
        border-radius: $rounded;
        height: 100%;
        width: 100%;

        &.contact {
          display: flex;
          flex-direction: column;
          gap: $gap;
        }
      }
    }
  }

  .header-title,
  .header-content {
    background-color: $color-bloc;
    position: relative;
    z-index: 2;
    overflow: visible;
  }

  @media(max-width: 1800px) {
    .bottom {
      &.flex-container {
        .content-container-bg {
          min-height: 18rem;
        }
      }
    }
  }

  @media(max-width: 1660px) {

    .bottom {
      .content-container-bg {
        min-height: 25rem;
        height: 100%;
        width: 100%;
      }

    }

    .btn-primary {
      line-height: 179%;
      font-size: 0.9rem;
      padding: 0.3em 1em;
    }
  }

  @media(max-width: 1399px) {
    .header-grid {
      gap: $gap;

      .top,
      .bottom {
        gap: $gap;
        grid-template-columns: 8fr 4fr;
        display: flex;
        flex-direction: column-reverse;
      }

      .top {
        .title-container {
          padding: 0;
        }

        .title-container-bg {
          display: none;
        }
      }

      .bottom {
        .content-container-bg {
          min-height: 35rem;
          border-radius: $rounded;
          background-size: cover;
        }

        .content-container {
          padding: 0;
        }

        &.flex-container {

          .content-container-bg {
            border-radius: $rounded;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .header-grid {

      .bottom {
        .content-container-bg {
          min-height: 32rem;
        }
      }
    }
  }

  /* Animation */
  .title-bg-hidden,
  .title-hidden,
  .bg-hidden,
  .content-hidden {
    opacity: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .show-title-bg {
    animation: fadeIn 0.3s forwards;
  }

  .show-title {
    animation: fadeIn 0.3s forwards;
  }

  .show-bg {
    animation: fadeIn 0.3s forwards;
  }

  .show-content {
    animation: fadeIn 0.3s forwards;
  }

}
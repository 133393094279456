@import "../../assets/styles/scss/variables";

#page-header.show {
  background-color: transparent;
  color: $color-text;
  position: relative;

  .post-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s linear;

    @media (max-width:991px) {
      display: none;
      visibility: hidden;
    }

    a {
      color: $color-bloc;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .4rem;
      
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.7rem;
        min-width: 1.7rem;
        height: 1.7rem;
        min-height: 1.7rem;
        border-radius: 50%;
        border: 1px solid $color-bloc;
        font-size: .7rem;
        line-height: normal;
      }

      &:hover {
        
        i {
          background-color: $color-bloc;
          color: $color-primary;
        }
      }

      span {
        writing-mode: vertical-rl;
        text-orientation: sideways;
        transform: rotate(180deg);
      }
    }

    &#prev-post {
      left: calc(-5.4rem - 2.9rem);

      @media (max-width:1260px) {
        left: calc(-5.4rem - 1rem)
      }

      &:hover {
        margin-left: -.5rem;
      }
    }
    &#next-post {
      right: calc(-5.4rem - 2.9rem);

      @media (max-width:1260px) {
        right: calc(-5.4rem - 1rem)
      }
      
      a {
        flex-direction: row-reverse;
  
        span {
          transform: rotate(360deg);
        }
      }

      &:hover {
        margin-right: -.5rem;
      }
    }

  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    @media (max-width:1199px) {
      display: flex;
      flex-direction: column;
    }
  }

  .poster {
    border-radius: $rounded;
    overflow: hidden;
    display: flex;
    padding: 1rem;
    background-color: white;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    background-color: $color-bloc;
    color: $color-text;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 2.5rem;
    border-radius: $rounded;
    position: relative;
    width: 100%;

    #rates {
      margin-top: 3rem;
    }

    .btn-primary {
      margin-left: auto;
      margin-top: 2rem;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .categories {
    text-transform: uppercase;
    color: $color-text;
    margin-top: .4rem;
  }

  .date {
    margin-bottom: .6rem;
  }
}

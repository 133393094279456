@import "../../assets/styles/scss/variables";

.bloc-wrapper {
  display: flex;
  align-items: flex-start;

  .bloc {
    width: 100%;
    align-self: stretch;
    position: relative;

    .btn-icon {
      position: absolute;
      right: 1.1rem;
      bottom: 1.1rem;
    }

    &.linked {
      transition: all .2s linear;
  
      &:hover {
        color: white;
        background-color: $color-primary;
  
        a {
          color: inherit;
        }
  
        .btn-icon {
          color: $color-primary;
          background-color: white;
        }
      }
    }
  }

  .vertical-title {
    transition: all .2s linear;
  }

  &:hover {
    @media (min-width:991px) {

      .vertical-title {
        transform: rotate(180deg) translateY(-1.2rem);
      }
    }

  }

  @media (max-width:991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

body.colored-bg {
  .bloc-wrapper {
    .bloc.linked {
      &:hover {
        background-color: $color-title;
      }
    }
  }
}
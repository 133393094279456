@import "../../assets/styles/scss/variables";

.member-card {
  display: flex;
  flex-direction: column;
  gap: 0.56rem;
  position: relative;
  cursor: pointer;

  .member-img {
    width: 100%;
    height: 16rem;
    transition: all 0.2s linear;
    overflow: hidden;

    img {
      scale: 1.1;
      transition: all 0.2s linear;
    }
  }

  .member-info {
    display: flex;
    height: 5.4rem;
    flex-direction: column;
    background-color: $color-bloc;
    padding: 0.89rem 1.28rem;
    transition: height 0.2s linear;

    .more {
      color: $color-primary;
      font-size: 0.7rem;
      opacity: 0;
      transition: opacity 0.5s linear;
      bottom: -8px;
      position: relative;
    }

    .name {
      color: $color-primary;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    .function {
      font-weight: 400;
      margin: 0;
      padding: 0 0 .6rem 0;
    }

    .btn-icon {
      position: absolute;
      right: .5rem;
      bottom: .5rem;
      font-size: 23px;

      i {
        margin-top: -1px;
      }
    }
  }

  &:hover {
    .member-img {
      height: 14rem;
      transition: all 0.1s linear;

      img {
        scale: 1;
        transition: all 0.1s linear;
      }
    }

    .btn-icon {
      background-color: $color-primary-dark;

      i {
        margin-top: -1px;
      }
    }

    .more {
      opacity: 1;
      padding: 0;
      margin: 0;
      transition: opacity 0.3s linear;
    }

    .member-info {
      height: 7.4rem;
      transition: height 0.1s linear;
    }

  }

}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  z-index: 1000;
  background-color: #00000063;
  justify-content: center;
  align-content: center;
  transition: opacity 0.5s ease;

  @keyframes popup-animation-in {
    from {
      scale: 0;
    }

    to {
      scale: 1;
    }
  }

  @keyframes popup-animation-out {
    from {
      scale: 1;
    }

    to {
      scale: 0;
    }
  }

  .popup-content {
    position: relative;
    display: none;
    max-width: 0;
    max-height: 0;
    overflow: auto;
    background-color: $color-bloc;
    padding: 2.22rem;
    animation: popup-animation-in 0.4s ease;

    .member-details {
      display: flex;
      gap: 3rem;

      .member-img {
        max-height: 22rem;
        max-width: 20rem;

        img {
          border-radius: $rounded;
          height: 100%;
          max-height: 22rem;
          object-fit: cover;
        }
      }

      p {
        margin: 0 0 3.5rem;
        padding: 0;
      }

      .infos {
        overflow-y: scroll;
        height: 35svh;
        padding-right: 2.22rem;
      }
    }
  }

  &.is-open {
    display: flex;    
    align-items: center;

    .popup-content {
      display: flex;
      min-height: 60svh;
      max-width: 76vw;
      transition-delay: 1s;

      &.is-closing {
        animation: popup-animation-out 0.6s ease;
      }
    }
  }

  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    border: 50%;
    background-color: $color-primary;
    transform: rotate(45deg);
  }

  @media (max-width:1399px) {

    &.is-open {
      .popup-content {
        .member-details {
          .member-img {
            height: 45%;
          }
        }
      }
    }
  }

  @media (max-width:991px) {

    &.is-open {
      .popup-content {
        min-height: 70svh;
        max-width: 95vw;

        .infos {
          height: 50svh;
        }


        .member-details {
          .member-img {
            height: 30%;
          }
        }
      }
    }
  }

  @media (max-width:767px) {

    &.is-open {

      .popup-content {
        overflow: hidden;
        padding: 1.7rem;

        .member-details {
          flex-wrap: wrap;
          gap: 1.5rem;

          .close-popup {
            font-size: 15px;
            width: 1.5rem;
            min-width: 1.5rem;
            height: 1.5rem;
            min-height: 1.5rem;
          }

          .name {
            font-size: clamp(1.2rem,5vw,3rem);
          }

          .infos {
            height: 32svh;
            padding-right: 1.22rem;
          }

          p {
            margin: 0 0 1rem 0;
          }

          .member-img {
            width: 30%;
          }
          .member-info {
            width: calc(65% - 1.5rem);
          }
        }
      }
    }
  }
}
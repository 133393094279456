@import "../../assets/styles/scss/variables";

.show-bloc {
  display: block;
  padding: 2rem;
  background-color: $color-bloc;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: flex-end;
  gap: $gap;
  width: 100%;
  overflow: hidden;

  h3 {
    font-size: 1.8rem;
    font-weight: 800;
    color: $color-primary;
    text-transform: uppercase;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .categories {
    text-transform: uppercase;
    color: $color-text;
    margin-top: .4rem;
  }

  .right {
    display: flex;
    align-items: center;
    position: relative;
  }

  .poster {
    position: absolute;
    top: 50%;
    right: calc(100% + 1.2rem);
    transform: translateY(-50%);
    width: 10rem;
    height: auto;
    opacity: 0;
    visibility: hidden;
    transition: all .2s linear;
    z-index: 1;
  }

  .dates {
    grid-column-start: 2;
  }

  &:hover:not(.archived) {
    background-color: $color-primary;
    color: white;
    overflow: visible;

    .poster {
      opacity: 1;
      visibility: visible;
      transform: rotate(-12deg) translateY(-50%);
    }

    h3, .categories {
      color: inherit;
    }
    
    .date {
      background-color: white;
      color: $color-primary;
    }
  }

  &.archived {
    background-color: #d5d2d2;
    &:hover {
      overflow: visible;

      .poster {
        opacity: 1;
        visibility: visible;
        transform: rotate(-12deg) translateY(-50%);
      }
    }

    h3 {
      color: black;
    }

    .date {
      color: black;
      background-color: gray;
    }
  }

  &.color-white {

    h3, .categories {
      color: white;
    }

    .date {
      background-color: white;
    }
  }

  &.color-black {

    h3, .categories {
      color: black;
    }

    .date {
      background-color: black;
    }
  }

  @media (max-width:1080px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;

    .poster {
      display: none;
    }
  }
}
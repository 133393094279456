//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //15px
  line-height: $text-base-line-height;
  font-weight: 500;
  color: $color-text;
  scroll-behavior: smooth;
  background-color: white;

  @media (max-width:767px) {
    font-size: $xsmall;
  }
}

body {
  position: relative;
  z-index: 1;

  img.theme-bg {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

.is-hidden,
.hidden {
  display: none !important;
}

.not-visible,
.invisible {
  visibility: hidden;
}

.bold {
  font-weight: 700 !important;
}

.xxsmall {
  font-size: $xxsmall;
  line-height: 180%;
  font-weight: 400;
}

.xsmall {
  font-size: $xsmall;
  line-height: 180%;
  font-weight: 400;
}

.small {
  font-size: $small;
  line-height: 180%;
  font-weight: 500;
}

p,
li,
.regular {
  font-size: clamp(1rem, 1.04vw, $regular);
  line-height: 166%;
  font-weight: 500;
}

p {
  margin: 1.5rem 0;
  font-weight: 500;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }
}

* p {
  font-size: inherit;
  line-height: inherit;
}

h6,
.medium {
  font-size: $medium;
  line-height: normal;
  font-weight: 600;
}

h5,
.big {
  font-size: $big;
  line-height: normal;
  font-weight: 600;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp(1.3rem, 2vw, $large);
  line-height: 135%;
  font-weight: 700;
}

h3,
.massive {
  font-size: clamp(1.8rem, 2.6vw, $massive);
  line-height: normal;
  font-weight: 700;
}

h2,
.huge {
  font-size: clamp(2rem, 3.65vw, $huge);
  line-height: normal;
  font-weight: 800;
}

h1,
.gigantic {
  font-size: clamp(3rem, 4.69vw, $gigantic);
  line-height: normal;
  font-weight: 800;
}

.swiper {

  .swiper-buttons {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $xxsmall;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    position: static;
    margin: 0;
    background-color: $color-primary;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &::after {
      font-size: .6rem;
      line-height: normal;
    }

    &:hover {
      background-color: $color-primary-dark;
    }

    &.swiper-button-lock {
      display: none !important;
    }
  }
}


//HELPERS
//===================
.grid {
  display: grid;
  gap: $gap;

  @media (max-width:991px) {
    &:not(ul.socials) {
      display: flex;
      flex-direction: column;
    }
  }
}

.bloc {
  border-radius: $rounded;
  background-color: $color-bloc;
  padding: 2rem;

  @media (max-width:767px) {
    padding: 1rem;
  }
}

.color-primary {
  color: $color-primary;
}

.color-primary-light,
.color-secondary {
  color: $color-primary-light;
}

.color-primary-dark,
.color-tertiary {
  color: $color-primary-dark;
}

.color-white {
  color: white;
}

.color-black {
  color: black !important;
}

.no-bg,
.bg-transparent {
  background-color: transparent;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-primary-light,
.bg-secondary {
  background-color: $color-primary-light;
}

.bg-primary-dark,
.bg-tertiary {
  background-color: $color-primary-dark;
}

.vertical-title {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  color: $color-title;
  text-transform: uppercase;
  line-height: .7;
  padding-top: $gap;

  @media (max-width:991px) {
    writing-mode: horizontal-tb;
    transform: none;
    padding: 0;
  }
}

.img-container {
  display: flex;
  overflow: hidden;
}

.rounded {
  overflow: hidden;
  border-radius: $rounded;
}

.btn-icon {
  color: white;
  background-color: $color-primary;
  border-radius: 50%;
  width: 1.7rem;
  min-width: 1.7rem;
  height: 1.7rem;
  min-height: 1.7rem;
  font-size: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s linear;

  i {
    font-size: inherit;
  }

  &:hover {
    background-color: $color-primary-dark;
  }
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  border-radius: $rounded;
  color: white;
  background-color: $color-primary;
  font-weight: 700;
  line-height: 180%;
  font-size: 1rem;
  padding: .9rem 2rem;
  cursor: pointer;
  transition: all .2s linear;

  &:hover,
  .reverse {
    background-color: $color-primary-dark;
  }

  @media (max-width:767px) {
    width: 100%;
  }
}
@import "../../assets/styles/scss/variables";

.accordion {
  margin-bottom: 1.5rem;
  background-color: $color-primary-light;
  overflow: visible;

  &:last-child {
    margin-bottom: 0;
  }

  .accordion-title {
    width: 100%;
    background-color: transparent;
    text-align: left;
    padding: .9rem 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    color: $color-primary;
    cursor: pointer;

    svg {
      transform-origin: center center;
      transition: transform .3s linear;
      width: 1.5rem;
      min-width: 1.5rem;
      height: auto;

      circle {
        fill: $color-primary;
      }
    }

    &.is-open {

      svg {
        transform: rotate(180deg);
        margin-top: calc(-.9rem - 2.5rem);
      }
    }
  }

  .accordion-content {
    display: none;
    font-weight: 400;
    color: $color-text;
    padding: 1.4rem;
    padding-top: 0;
    max-width: calc(100% - 2rem);

    #rates {

      .rate {
        border-bottom-color: $color-primary;
      }
    }

    p {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    a {
      color: $color-primary;

      &:hover {
          color: $color-primary-dark;
      }
    }
  }
}

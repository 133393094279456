@import "../../assets/styles/scss/variables";

.partner-bloc {
  display: flex;
  padding-top: 66.67%;
  position: relative;

  img {
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .2s linear;
  }
}

a.partner-bloc {
  &:hover {
    
    img {
      transform: scale(1.05);
    }
  }
}
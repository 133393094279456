@import "../../assets/styles/scss/variables";

.bloc-img {
  grid-template-columns: auto 33%;

  .img-container {
    overflow: hidden;
    border-radius: $rounded;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width:1260px) {
    grid-template-columns: auto 25%;
  }

  @media (max-width:1199px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	overflow-x: hidden !important;
}

body {
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
}

.onscroll {
	overflow-y: hidden;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: all .2s linear;

	&:hover {
		outline: none;
		color: $color-secondary;
		text-decoration: none;

		i {
			color: inherit;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

address {
	font-style: normal;
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

.gform_required_legend {
	display: none;
}

form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: $gap;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: $gap;
		row-gap: $gap;

		@media (max-width:767px) {
			display: flex;
			flex-direction: column;
		}

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover {
					background-color: $color-secondary;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}

				&:focus {
					border: 2px solid $color-secondary !important;
				}
			}
		}

		.gfield_list_group {

			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&:focus-within {

				label,
				.gfield_label {
					opacity: 0;
				}

				.gfield_required {
					color: transparent;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					border-color: $color-red;
				}
			}

			.gfield_label_before_complex {
				display: none;
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				width: fit-content;
				transition: all .2s linear;

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
				}

				label {
					padding-left: 5px;
					font-weight: 600;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					color: $color-text;
					transition: all .2s linear;

					&::before {
						position: absolute;
						top: 50%;
						left: -20px;
						transform: translateY(-50%);
						content: "";
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: 2px solid $color-primary;
						background-color: transparent;
						transition: all .2s linear;
					}
				}

				.gfield-choice-input:checked~label {
					font-weight: 600;

					&::before {
						background-color: $color-primary !important;

					}
				}

				&:hover {

					label {
						&::before {
							background-color: $color-primary;
						}
					}
				}
			}

			.gfield_checkbox {

				.gchoice {

					input {
    					position: absolute;
					}

					label {
						padding-left: 10px;
						display: flow;
						left: 20px;
						padding-right: 20px;
						font-size: 12px;

						&::before {
							position: absolute;
							top: 50%;
							left: -20px;
							transform: translateY(-50%);
							content: "";
							width: 16px;
							height: 16px;
							border-radius: 3px;
							border: 2px solid $color-primary;
							background-color: transparent;
							transition: all .2s linear;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: $small;
		line-height: normal;
		font-weight: 600;
		color: $color-primary;
		position: absolute;
		left: 1rem;
		top: 1.2rem;
		transition: all .1s linear;
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;
		background-color: $color-bloc;
		border-radius: $rounded;
		padding: 1.2rem 1rem !important;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		border-radius: $rounded;
		color: $color-primary;
		padding: 1.2rem 1rem;
		appearance: none;
		resize: none;
		background-color: $color-bloc;
		font-size: $small;
		line-height: normal;
		font-family: inherit;
		font-weight: 600;

		&::placeholder {
			font-size: inherit;
			color: transparent;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 0 !important;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-style: italic;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

button {
	all: unset;
	cursor: pointer;
}

*[type="button"] {
	cursor: pointer;
}

button,
input[type="submit"],
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button,
.woocommerce a.button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: fit-content;
	border-radius: $rounded;
	color: white;
	background-color: $color-primary;
	font-weight: 700;
	line-height: 180%;
	font-size: 1rem;
	padding: .9rem 2rem;
	cursor: pointer;
	transition: all .2s linear;

	&:hover {
		background-color: $color-primary-dark;
	}

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}

	@media (max-width:767px) {
		width: 100%;
	}
}

.container,
.container-fluid {
	position: relative;
	max-width: 1920px;
	margin-inline: auto;
}

.container {
	width: 75vw;

	@media (max-width:576px) {
		width: 85vw;
	}

	.inner-container {
		width: 83%;
		margin-inline: auto;

		@media (max-width:991px) {
			width: 100%;
		}
	}
}

.container-fluid {

	.inner-container {
		width: 96.5%;
		margin-left: auto;

		@media (max-width:2560px) {
			width: 93%;
		}

		@media (max-width:991px) {
			width: 94vw;
		}

		@media (max-width:576px) {
			width: 85vw;
			margin-inline: auto;
		}
	}
}


.ts-wrapper {
	width: fit-content;

	.ts-control {
		font-size: $huge;
		line-height: normal;
		font-weight: 800;
		color: $color-title;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		gap: 1rem;
		background-color: transparent;
		transition: all .2s linear;
		border-radius: 2px;
		padding: 1.3rem 1.2rem !important;
		padding-right: 3.2rem !important;
		position: relative;
		text-transform: uppercase;
		min-width: 3.5rem;

		&::after {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0;
			background-color: transparent;
			content: "\f107";
			font-family: $fontawesome;
			font-weight: bold;
			color: $color-title;
			font-size: $big;
			position: absolute;
			top: 50%;
			right: $medium;
			transform: translateY(-50%);
			transition: all .2s linear;
		}
	}

	.ts-control,
	.ts-dropdown {
		color: $color-title;
		font-size: $huge;
		line-height: normal;
		border: 0;
		border-radius: $rounded;
		box-shadow: none;

		input {
			font-size: inherit;
			line-height: inherit;
		}
	}

	.ts-dropdown {
		margin-top: 0;

		.option {
			padding: .8rem 1.2rem;
			text-transform: uppercase;
			font-weight: 800;
			color: $color-primary;
		}

		.selected {
			color: $color-primary;
			background-color: $color-primary-light;

			&.active {
				color: $color-primary;
				background-color: $color-primary-light;
			}
		}

		.active {
			color: $color-primary;
			background-color: $color-primary-light;
		}
	}

	&.input-active {

		.ts-control {
			color: $color-primary;
		}
	}

	&.dropdown-active {

		.ts-control,
		.ts-dropdown {
			color: $color-primary;
		}

		.ts-control {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&::after {
				transform: translateY(-50%) rotate(180deg);
				color: $color-primary;
			}
		}

		.ts-dropdown {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}


.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {
	.error-details {
		margin-bottom: 30px;
	}

	.error-actions {
		.button {
			&:hover {
				i {
					color: #ffffff;
				}
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

#page-header {
	position: relative;

	&.standard {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		color: #ffffff;
		height: 500px;

		.video {
			position: absolute;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -1;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.slider {
		.slide {
			position: relative;
			height: 500px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			color: #ffffff;
		}
	}

	.link {
		margin-top: 30px;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.page-header-content {
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);

		.title {
			margin: 0;
		}
	}

}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}

/* Scrollbar (box) */
::-webkit-scrollbar {
	width: 12px;
	border-radius: 10rem;
}

::-webkit-scrollbar-track {
	background: $color-bloc;
	border-radius: 10rem;
}

::-webkit-scrollbar-thumb {
	background: $color-primary;
	border-radius: 10rem;
}

::-webkit-scrollbar-thumb:hover {
	background: $color-primary-dark;
	border-radius: 10rem;
}

body.colored-bg {

	form {

		.gform_footer {

			button {
				background-color: $color-bloc;
				color: $color-primary;

				&:hover {
					background-color: $color-primary-dark;
					color: $color-bloc;
				}
			}
		}
	}
}